.table-container {
  width: 80%;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.table-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 15px;
  color: #3f51b5;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-table thead {
  background-color: #f5f5f5;
}

.custom-table th,
.custom-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}

.custom-table th {
  color: #3f51b5;
  font-weight: bold;
}

.custom-table tr:hover {
  background-color: #f1f1f1;
}

/* Estilo del select */
.custom-select {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  outline: none;
  transition: all 0.3s ease;
}

.custom-select:focus {
  border-color: #3f51b5;
  box-shadow: 0px 0px 5px rgba(63, 81, 181, 0.3);
}

/* Paginación */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  padding: 8px 16px;
  margin: 0 5px;
  border: none;
  background-color: #3f51b5;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: #303f9f;
}

.pagination span {
  font-size: 16px;
  color: #333;
  margin: 0 10px;
}



.table__btnSuccess{
  background-color: green;
  outline: none;
  border: none;
  color: white;
  padding: 5px 10px;
}
.table__btnSuccess:hover{
  background-color: white;
  color: green;
}